'use client';
import React, { createContext, useState, useContext, ReactNode } from 'react';

interface VideoContextType {
    currentVideoId: string | null;
    currentTime: number;
    setCurrentVideo: (id: string, time: number) => void;
    pauseCurrentVideo: () => void;
}

const VideoContext = createContext<VideoContextType | undefined>(undefined);

export const useVideoContext = () => {
    const context = useContext(VideoContext);
    if (!context) {
        throw new Error('useVideoContext must be used within a VideoProvider');
    }
    return context;
};

export const VideoProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [currentVideoId, setCurrentVideoId] = useState<string | null>(null);
    const [currentTime, setCurrentTime] = useState<number>(0);

    const setCurrentVideo = (id: string, time: number) => {
        setCurrentVideoId(id);
        setCurrentTime(time);
    };

    const pauseCurrentVideo = () => {
        setCurrentVideoId(null);
        setCurrentTime(0);
    };

    return (
        <VideoContext.Provider value={{ currentVideoId, currentTime, setCurrentVideo, pauseCurrentVideo }}>
            {children}
        </VideoContext.Provider>
    );
};
