import utilsService from '@/app/helpers/utilsService';
import fetchWrapper from '../fetchWrapper';

const BASE_URL = process.env.BASE_URL;

async function authenticationLogin(accessToken: string, type: string, fcm_token?: string | null, platform?: 'ios'| 'android'): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/auth/login`, {
            input_token: accessToken,
            type,
            fcm_token,
            platform
        });
        return response;
    } catch (error: any) {
        throw new Error(`${error.message ?? 'Failed to authenticate with Google'}`);
    }
}

async function refreshToken(): Promise<any> {
    try {
        const response = await fetchWrapper.post(`${BASE_URL}/auth/refresh-token`);
        return response;
    } catch (error: any) {
        throw new Error(`${error.response?.data || error.message}`);
    }
}

async function logout({fcm_token}: {fcm_token?: string}): Promise<void> {
    try {
        await fetchWrapper.post(`${BASE_URL}/auth/logout`, await utilsService.appendToFormData({ fcm_token }));
    } catch (error: any) {
        throw error;
    }
}

export const authService = {
    authenticationLogin,
    refreshToken,
    // authenticationLoginGoogle,
    // authenticationLoginApple,
    logout,
};
