'use client';
import BaseModal from '@/app/base-ui/BaseModalUI';
import { useGlobalDialogV2 } from '@/app/contexts/GlobalDialogV2';
import React from 'react';

const GlobalDialogActions = () => {
    const { dialogState, hideDialog } = useGlobalDialogV2();
    const { isOpen, title, message, showCancel, icon, showAccept, baseCardContent, showBaseCardNotice, onCancel, onAccept, cancelText, acceptText } = dialogState;

    // if (!isOpen) return null;

    return (
        <BaseModal
            aria-modal={true}
            sx={{ '& .MuiPaper-root': { bgcolor: '', maxWidth: '335px', width: '100%', zIndex: 99999 } }}
            open={isOpen}
            title={title}
            icon={icon}
            description={message}
            showCancel={showCancel}
            baseCardContent={baseCardContent}
            showBaseCardNotice={showBaseCardNotice}
            showAccept={showAccept}
            cancelText={cancelText || '취소'}
            acceptText={acceptText || '확인'}
            onCancel={onCancel ? onCancel : hideDialog}
            onAccept={onAccept ? onAccept : hideDialog}
        />
    );
};

export default GlobalDialogActions;
